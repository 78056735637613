import React from 'react'
import Panel from '../panel'
import { FormattedMessage } from 'react-intl'
import MailIcon from '../../assets/img/icons/ico-mail.svg'
import PhoneIcon from '../../assets/img/icons/ico-phone.svg'

export default function NegotiatorCard({ property }) {
  const hasPhoto = property.negotiator.data.photo?.data?.photo_file_id

  return (
    <Panel title={<FormattedMessage id="your-agent" />}>
      <div className="agent">
        {hasPhoto && (
          <div className="agent__image">
            <img
              src={`https://cdn.sweepbright.com/users/presets/avatar-medium/${property.negotiator.data.photo?.data?.photo_file_id}`}
              alt=""
            />
          </div>
        )}
        <div className="agent__info">
          <h2 className="agent__name">
            {property.negotiator.data.first_name}{' '}
            {property.negotiator.data.last_name}
          </h2>
          <span className="agent__email">
            <i className="o-icon">
              <MailIcon />
            </i>
            <a href={`mailto:${property.negotiator.data.email}`}>
              {property.negotiator.data.email}
            </a>
          </span>
          {property.negotiator.data.phone && (
            <span className="agent__phone">
              <i className="o-icon">
                <PhoneIcon />
              </i>
              <a href={`tel:${property.negotiator.data.phone}`}>
                {property.negotiator.data.phone}
              </a>
            </span>
          )}
        </div>
      </div>
    </Panel>
  )
}
