import React from 'react'

export function AreaUnit({ unit }) {
  switch (unit) {
    case 'sq_m': {
      return (
        <span>
          m<sup>2</sup>
        </span>
      )
    }
    default:
      return <span>{unit}</span>
  }
}
