import React from 'react'
import { useBoolean } from 'react-use'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { PropertyPrice } from './property/property-price'
import { ProjectPrice } from './property/project-price'
import { UnitCount } from './property/unit-count'
import { MapModal } from './property/map-modal'
import _ from 'lodash'
import { useHeaderImage } from './property/image'
import $ from 'jquery'
import { TransChoice } from './trans-choice'
import { getPanoramaUrl } from '../utils/helpers'

const PropertyHeader = ({ property, locale }) => {
  const headerImage = useHeaderImage({ property, type: 'agency-website' })

  return (
    <div className="c-row c-row--beta c-row--collapsed">
      {headerImage && <HeaderImage image={headerImage} />}
      <div
        className={classNames('o-container', {
          'property-detail-wrapper': headerImage?.url,
        })}
        style={{ pointerEvents: 'none' }}
      >
        {headerImage?.url && (
          <button
            className="property-detail-lightbox-button c-button c-button--neutral c-button--borderless c-button--sm print-display-none"
            id="main-image-button"
            type="button"
            onClick={() => {
              $('#light-gallery a').first().trigger('click')
            }}
            style={{ pointerEvents: 'auto' }}
          >
            <FormattedMessage id="see-all-images" />
          </button>
        )}
        <section className="o-grid" style={{ pointerEvents: 'auto' }}>
          <div className="o-grid__item">
            <article
              className="property property--detail"
              style={{
                marginLeft: '-0.75rem',
                marginRight: '-0.75rem',
              }}
            >
              <ul className="property__infoblocks">
                <li>
                  {property.internal_type === 'project' ? (
                    <ProjectPrice project={property} />
                  ) : (
                    <PropertyPrice property={property} />
                  )}
                </li>
                <li>
                  {property.internal_type === 'project' ? (
                    <UnitCount project={property} />
                  ) : isLiveable(property) ? (
                    <Bedrooms property={property} />
                  ) : (
                    <PropertyType property={property} />
                  )}
                </li>
                <li>
                  <FormattedMessage
                    id={
                      property.negotiation == 'let'
                        ? 'page.to-let'
                        : 'page.for-sale'
                    }
                  />
                </li>
              </ul>
              <div className="property__info">
                <h2 className="property__title">
                  {property.attributes.description.title[locale]}
                </h2>
                <span className="property__address">
                  <PropertyLocation property={property} />
                </span>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  )
}

export default PropertyHeader

// elements

function HeaderImage({ image }) {
  if (image.url) {
    return (
      <div className="property-detail-image">
        {image.equirectangular ? (
          <iframe
            allowfullscreen="true"
            scrolling="no"
            width="100%"
            height="100%"
            src={getPanoramaUrl(image.id)}
            style={{ border: 0 }}
          />
        ) : (
          <div
            className="property-detail-image"
            id="main-image"
            style={{ backgroundImage: `url(${image.url})` }}
          />
        )}
      </div>
    )
  }
  return null
}

const Bedrooms = ({ property }) => {
  return (
    <>
      {property.attributes.structure.bedrooms}{' '}
      <TransChoice
        count={property.attributes.structure.bedrooms}
        id="plurals.bedrooms"
      />
    </>
  )
}

const PropertyType = ({ property }) => {
  if (property.is_project) {
    return <FormattedMessage id="project.units.empty" />
  } else if (property.is_liveable) {
    return (
      <FormattedMessage
        id="bedrooms"
        values={{
          bedrooms: property.structure.bedrooms,
        }}
      />
    )
  } else {
    return <FormattedMessage id={`property.types.${property.type}`} />
  }
}

const PropertyLocation = ({ property }) => {
  const [isMapModalOpen, toggleMapModal] = useBoolean(false)
  const showLocation = property.publish_location
  if (showLocation) {
    if (property.attributes.location) {
      return (
        <>
          <MapModal
            isOpen={isMapModalOpen}
            onClose={toggleMapModal}
            property={property}
          />
          {property.attributes.location.formatted_agency}
          <a onClick={toggleMapModal} className="inline-flex items-center px-2">
            <FormattedMessage id="open-map" />{' '}
            <svg viewBox="0 0 13 19" style={{ width: 16 }} className="pl-1">
              <path
                d="M6.167 18.598c-1.418 0-4.737-5.318-4.737-7.586 0-2.574 2.126-4.668 4.737-4.668 2.612 0 4.736 2.094 4.736 4.668 0 2.268-3.32 7.586-4.736 7.586m0-11.088c-1.96 0-3.553 1.571-3.553 3.502 0 1.967 2.72 5.888 3.553 6.39.832-.502 3.553-4.423 3.553-6.39 0-1.931-1.594-3.502-3.553-3.502zm-4.3-2.946a.568.568 0 0 1-.452-.221L.116 2.663a.553.553 0 0 1 .108-.783.572.572 0 0 1 .794.106l1.3 1.68a.553.553 0 0 1-.109.783.57.57 0 0 1-.343.115zm8.317-.212a.57.57 0 0 1-.343-.114.555.555 0 0 1-.108-.784l1.3-1.68a.573.573 0 0 1 .794-.106.555.555 0 0 1 .107.784l-1.298 1.68a.57.57 0 0 1-.452.22zM6.051 3.23a.563.563 0 0 1-.568-.559V.559c0-.309.255-.559.568-.559.313 0 .567.25.567.559V2.67a.563.563 0 0 1-.567.559zm.116 6.615c.491 0 .888.392.888.875a.881.881 0 0 1-.888.876.882.882 0 0 1-.888-.876c0-.483.398-.875.888-.875z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </a>
        </>
      )
    } else {
      return <FormattedMessage id="location-not-provided" />
    }
  }

  return <FormattedMessage id="location-on-request" />
}

function isLiveable(property) {
  return ['house', 'apartment'].includes(property.type)
}
