import React from 'react'
import Panel from '../panel'
import { FeatureItem } from '../feature'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { TransChoice } from '../trans-choice'

const ROOMS = [
  'bedrooms',
  'bathrooms',
  'toilets',
  'floors',
  'showrooms',
  'manufacturing_areas',
  'storage_rooms',
]

const FeaturesPanel = ({ property }) => {
  if (property.internal_type === 'project') {
    return null
  }

  const features = [
    ...(property.attributes.shapes ?? []).map((shape) => {
      return (
        <FeatureItem
          key={`shapes_${shape}`}
          description={<FormattedMessage id={`shapes.${shape}`} />}
        />
      )
    }),
    ...ROOMS.map((room) => {
      if (_.get(property, `attributes.structure.${room}`)) {
        const roomCount = _.get(property, `attributes.structure.${room}`)
        return (
          <FeatureItem
            description={
              <>
                <b>{roomCount}</b>{' '}
                <TransChoice count={roomCount} id={`plurals.${room}`} />
              </>
            }
          />
        )
      }
      return null
    }),
    ...(_.get(property, 'attributes.amenities') || []).map((amenity) => (
      <FeatureItem description={`amenities.${amenity}`} />
    )),
    ...(_.get(property, 'attributes.features.comfort') || []).map((feature) => (
      <FeatureItem description={`features.comfort.${feature}`} />
    )),
    ...(
      _.get(property, 'attributes.features.security') || []
    ).map((feature) => (
      <FeatureItem description={`features.security.${feature}`} />
    )),
  ].filter(Boolean)

  if (features.length === 0) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="features.title" />}>
      <div className="o-grid o-grid--gutter">
        <div className="o-grid__item">
          <ul className="c-feature-list c-feature-list--checked c-feature-list--split-3">
            {features}
          </ul>
        </div>
      </div>
    </Panel>
  )
}

export default FeaturesPanel
