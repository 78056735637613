import React from 'react'
import Panel from '../panel'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

export function CostPanel({ property, locale }) {
  if (property.internal_type === 'project') {
    return null
  }
  const { costs, taxes } = property.attributes.price ?? {}

  if (!costs?.[locale] && !taxes?.[locale]) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="costs-and-taxes" />}>
      <div className="o-grid o-grid--gutter o-grid--spaced-vertical">
        {costs?.[locale] && (
          <div className="o-grid__item u-1-of-2-bp3">
            <h3 className="panel__subtitle">
              <FormattedMessage id="price-costs" />
            </h3>
            <p>{costs[locale]}</p>
          </div>
        )}
        {taxes?.[locale] && (
          <div className="o-grid__item u-1-of-2-bp3">
            <h3 className="panel__subtitle">
              <FormattedMessage id="price-taxes" />
            </h3>
            <p>{taxes[locale]}</p>
          </div>
        )}
      </div>
    </Panel>
  )
}
