import React from 'react'
import Panel from '../panel'
import { Embedly } from '../embedly'
import { FormattedMessage } from 'react-intl'

export default function VideoPanel({ property }) {
  if (!property.attributes.video) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="video" />}>
      <div className="c-video">
        <Embedly
          url={property.attributes.video}
          apiKey="1002736901964502af4dba8505057cf1"
        />
      </div>
    </Panel>
  )
}
