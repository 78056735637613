import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAgency } from '../../hooks/use-agency'
import { getThemeColor } from '../../theming/helpers'
import { mapboxgl } from '../../utils/maps'
import { Modal } from '../modal'

export function MapModal({ property, isOpen, onClose }) {
  const [mode, setMode] = React.useState('map')

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage id="location" />}
    >
      {mode === 'map' && (
        <SimpleMap coordinates={property.attributes.location.geo} />
      )}
      {mode === 'street-view' && (
        <StreetView coordinates={property.attributes.location.geo} />
      )}
      <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
        <div className="c-button-fixed">
          <button
            type="button"
            className="c-button c-button--md c-button--alpha c-button--block"
            onClick={() => {
              setMode(mode === 'map' ? 'street-view' : 'map')
            }}
          >
            {mode === 'map' ? (
              <FormattedMessage id="street-view" />
            ) : (
              <FormattedMessage id="map" />
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

function SimpleMap({ coordinates }) {
  const { settings } = useAgency()
  const ref = React.useRef()
  React.useEffect(() => {
    const map = new mapboxgl.Map({
      container: ref.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      pitchWithRotate: false,
      touchPitch: false,
      center: {
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      },
      zoom: 18,
    })

    map.addControl(new mapboxgl.NavigationControl())
    new mapboxgl.Marker({ color: getThemeColor(settings) })
      .setLngLat({
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      })
      .addTo(map)
  }, [])

  return (
    <div
      ref={ref}
      className="propertyMap"
      style={{
        width: '100%',
        height: '100%',
        minHeight: '60vh',
        position: 'relative',
        overflow: 'hidden',
      }}
    />
  )
}

// street view uses
// the Maps Embed API
// https://developers.google.com/maps/documentation/embed/get-started
function StreetView({ coordinates }) {
  return (
    <div
      className="flex "
      style={{
        width: '100%',
        height: '100%',
        minHeight: '60vh',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <iframe
        className="w-full h-full"
        frameborder="0"
        style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/streetview?key=${process.env.GATSBY_GOOGLE_STATIC_MAPS_API_KEY}&location=${coordinates.latitude},${coordinates.longitude}&heading=210&pitch=10&fov=35`}
      />
    </div>
  )
}
